<template>
    <el-dialog
      :visible.sync="dialog"
      append-to-body
      title="查看"
      width="900px"
    >
      <el-form
        label-position="right"
        label-width="100px"
      >
        <el-form-item label="描述">
          <div style="width: 370px;" class="similar-input">
            {{rowData.describe}}
          </div>
        </el-form-item>
        <el-form-item
          label="分类"
        >
          <div style="width: 370px;" class="similar-input">
            {{getType()}}
          </div>
        </el-form-item>
        <el-form-item label="收件人">
          <div class="similar-input">
            {{rowData.custId}}
          </div>
        </el-form-item>
        <el-form-item label="内容">
          <div class="similar-input content overflow-auto" v-html="rowData.noticeContent"></div>
        </el-form-item>
      </el-form>
    </el-dialog>
</template>

<script>
  export default {
    name: 'emailLookDetail',
    props: {
      rowData:{}
    },
    data() {
      return {
        dialog: false,
      }
    },
    methods: {
      getType() {
        switch (this.rowData.noticeType) {
          case 1:
            return '代办';
          case 2:
            return '账户';
          case 3:
            return '订单';
          case 4:
            return '客户';
          case 5:
            return '公告';
          case 6:
            return '其他';
        }
      }
    }
  }
</script>

<style lang="less" scoped>
.content {
  height: auto;
  min-height: 32px;
}
</style>
