<template>
  <div class="app-container">
    <eHeader :query="query" @clear="clearHandel" @toQuery="toQuery"/>
    <!--表格渲染-->
    <el-table v-loading="loading" :data="data" size="small" stripe style="width: 100%;">
      <el-table-column prop="id" label="编号"/>
      <el-table-column prop="custId" label="收件人ID"/>
      <el-table-column prop="userName" label="收件人"/>

      <el-table-column prop="mailGroupId" label="群发ID"/>
      <el-table-column prop="describe" label="描述"/>
      <el-table-column prop="createTime" label="发送时间">
        <template slot-scope="scope">
          <span>{{parseTime(scope.row.createTime)}}</span>
        </template>
      </el-table-column>
      <el-table-column label="操作" width="300px" align="center">
        <template slot-scope="scope">
          <el-button
            v-if="getLookFlag()"
            @click="lookHandle(scope.row)"
            size="mini"
            type="success"
            :loading="btnLoading"
          >查看
          </el-button>
        </template>
      </el-table-column>
    </el-table>
    <!--分页组件-->
    <public-pagination
            :total-page="total"
            :current-page="page"
            @pageChange="pageChange"
            @sizeChange="sizeChange"
    ></public-pagination>
    <message-detail-dialog
      ref="emaillogdetail"
      :row-data="rowData"
    ></message-detail-dialog>
  </div>
</template>

<script>
  import { mapGetters } from 'vuex'
  import checkPermission from '@/utils/permission'
  import initData from '@/mixins/initData'
  import { parseTime } from '@/utils/index'
  import eHeader from '@/components/notificationManager/messageLog/header'
  import messageDetailDialog from '@/components/notificationManager/messageLog/messageDetailDialog'

  export default {
    name: 'messageLog',
    components: {
      eHeader,
      messageDetailDialog,
    },
    mixins: [initData],
    data() {
      return {
        query: {
          custInfo: '',
          mailGroupId: '',
        },
        delLoading: false,
        rowData: {},
        btnLoading: false
      }
    },
    created() {
      if(this.messageOrMailRecordId){
        this.query.mailGroupId = this.messageOrMailRecordId;
      }
      this.$nextTick(() => {
        this.init()
      })
    },
    beforeDestroy() {
      this.$store.commit('setMessageOrMailRecordId', '')
    },
    computed: {
      ...mapGetters(['messageOrMailRecordId'])
    },
    methods: {
      parseTime,
      beforeInit() {
        this.url = 'crm/tCustNotice'
        const sort = 'id,desc'
        this.params = {
          page: this.page,
          size: this.size,
          sort: sort
        }
        if (this.query.custInfo) {
          this.params.custInfo = this.query.custInfo
        }
        if (this.query.mailGroupId) {
          this.params.mailGroupId = this.query.mailGroupId
        }
        return true
      },
      clearHandel() {
        this.query = {
          custInfo: '',
          mailGroupId: '',
        }
        this.toQuery();
      },
      getLookFlag() {
        return checkPermission(['ADMIN', 'SysSendMailQuartz_ALL', 'SysSendMailQuartz_EDIT'])
      },
      lookHandle(row) {
        this.rowData = row;
        this.$refs.emaillogdetail.dialog = true
      }
    }
  }
</script>

<style scoped>

</style>
